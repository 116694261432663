.auth_container{
  font-size: .813rem;
  background-color: rgb(240, 241, 247);
  color: #333335;
  line-height: 1.5;
  text-align: start;
  overflow-x: clip;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.authentication_desktop_logo {
  height: 4rem;
  line-height: 2rem;
}